import React from 'react';
import theme from '../gatsby-theme-material-ui-top-layout/theme';
import { Box } from '@mui/material';
import { display } from '@mui/system';

const styles = {
  root: {
    '& a': {
      color: '#000',
    },
    '& > blockquote': Object.assign(
      {
        padding: theme.spacing(4),
        margin: 'auto',
        textAlign: 'center',
        maxWidth: 600,
        '& > ul': Object.assign(
          {
            fontStyle: 'italic',
            '& > li': {
              listStyle: 'none',
              textAlign: 'right',
              paddingRight: '15%',
              fontWeight: 400,
            },
          },
          theme.typography.body1
        ),
        '& > blockquote': {
          fontFamily: theme.typography.body1.fontFamily,
          fontSize: theme.typography.h6.fontSize,
          fontWeight: 400,
          lineHeight: theme.typography.body1.lineHeight,
          margin: `0 -${theme.spacing(4)}`,
          textAlign: 'left',
          '& > ul': {
            fontStyle: 'italic',
            '& > li': {
              textAlign: 'right',
              paddingRight: '15%',
              listStyle: 'none',
            },
          },
        },
      },
      theme.typography.h5
    ),
    '& > p': Object.assign(
      {
        marginTop: 0,
      },
      theme.typography.body1
    ),
    '& > ul': {
      listStyle: (props) => (props.hideBullets ? 'none' : 'initial'),
      '& > li': {
        ...theme.typography.body1,
        marginBottom: theme.spacing(2),
      },
    },
    '& > h3': theme.typography.h3,
    '& > h2': theme.typography.h2,
    '& > h1': theme.typography.h1,
    '& > p > a.gatsby-resp-image-link': {
      margin: {
        xs: `${theme.spacing(4)} 0`,
        md: `${theme.spacing(8)} 0`,
      },
    },
    '& > hr': {
      margin: `${theme.spacing(4)} 0`,
      color: '#000',
    },
    '& > div.footnotes': {
      '& > hr': {
        margin: `${theme.spacing(4)} 0`,
        color: '#000',
      },
      '& > ol': {
        paddingLeft: theme.spacing(3),
        counterReset: 'section',
        '& > li': {
          ...theme.typography.body2,
          '&::marker': theme.typography.body2,
          '& > *': {
            ...theme.typography.body2,
            display: 'inline-block',
          },
        },
      },
    },
  },
  underlineOnHover: {
    '& a': {
      color: '#000',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
};

const fixHtml = (html) => {
  return html.replace(
    /href="http/g,
    'target="_blank" rel="noopener noreferrer" href="http'
  );
};

const createMarkDown = (html) => {
  return { __html: fixHtml(html) };
};

const processHtml = (html, gallery, props) => {
  // console.log(html)

  // add captions?

  // handle new lines (doesn't work on content with images)
  if (props.newLinesAsBreaks && !html.includes('gatsby-resp-image-link'))
    html = html.replace(/\n/g, '<br />');

  return html;
};

const Markdown = (props) => {
  const { markdown, gallery, sx, suppressLinkUnderlines } = props;

  const html = processHtml(markdown.childMarkdownRemark?.html, gallery, props);

  // console.log(html)

  return (
    <React.Fragment>
      <Box
        sx={{
          ...styles.root,
          ...(Boolean(sx) && { ...sx }),
          ...(suppressLinkUnderlines && { ...styles.underlineOnHover }),
        }}
        dangerouslySetInnerHTML={createMarkDown(html)}
      />
    </React.Fragment>
  );
};

export default Markdown;
