import React, { useState } from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";
import { Typography, Grid, useMediaQuery, Box } from "@mui/material";
import theme from "../gatsby-theme-material-ui-top-layout/theme";

const styles = {
  root: {
    width: "100%",
    height: "100%",
    padding: {
      xs: theme.spacing(1),
      md: theme.spacing(2),
    },
    display: "flex",
    alignItems: "center",
  },
  gridWrapper: {
    color: "#000",
    padding: {
      xs: theme.spacing(1),
      md: theme.spacing(3),
    },
  },
  invert: {
    filter: "invert(1)",
    color: "#fff",
  },
};

const LogoCollection = (props) => {
  const { sx, invert } = props;
  const [rowHeight, setRowHeight] = useState(200);

  const smDown = useMediaQuery(theme.breakpoints.down("sm"));

  const data = useStaticQuery(graphql`
    query HeaderQuery {
      contentfulImageCollection(key: { eq: "press-logos" }) {
        id
        images {
          title
          description
          gatsbyImageData(width: 300, formats: PNG, sizes: "300px")
        }
      }
    }
  `);

  const { images: logos } = data.contentfulImageCollection;

  return (
    <Box
      sx={{
        ...styles.root,
        ...(invert && { ...styles.invert }),
        ...(Boolean(sx) && { ...sx }),
      }}
    >
      <Box sx={styles.gridWrapper}>
        <Grid container direction="column" spacing={smDown ? 4 : 8}>
          <Grid item>
            <Typography variant="h6">BVA projects have appeared in:</Typography>
          </Grid>
          <Grid item container spacing={smDown ? 2 : 4}>
            {logos.map((logo, index) => (
              <Grid key={"key-" + index} item xs={4} sm={3} md={2}>
                <GatsbyImage
                  image={getImage(logo.gatsbyImageData)}
                  title={logo.title}
                  alt={logo.description}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default LogoCollection;
