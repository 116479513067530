import React from "react";
import { graphql, navigate } from "gatsby";
import { Typography, Grid } from "@mui/material";
import PageLayout from "../components/pageLayout";
import Markdown from "../components/markdown";
import LogoCollection from "../components/logoCollection";
import theme from "../gatsby-theme-material-ui-top-layout/theme";
import { genHelmet } from "../actions/baseActions";

const styles = {
  root: {
    padding: {
      sx: `${theme.spacing(7.5)} 0 ${theme.spacing(6)} 0`,
      lg: `${theme.spacing(7.5)} ${theme.spacing(6)} ${theme.spacing(
        6
      )} ${theme.spacing(6)}`,
    },
    "& > div": {
      marginBottom: theme.spacing(8),
    },
  },
};

const Press = (props) => {
  const pageData = props.data.contentfulPage;
  const { contentCollection } = pageData;

  return (
    <React.Fragment>
      {genHelmet(pageData, {
        type: "page",
        // siteData: pageData,
        path: props.path,
      })}

      {/* --- LAYOUT --- */}
      <PageLayout location={props.location}>
        <Grid direction="column" container sx={styles.root}>
          <Grid item>
            <Typography variant="h3">{pageData.title}</Typography>
          </Grid>
          {/* <Grid item>
            <LogoCollection
              sx={{
                margin: {
                  xs: 0,
                  md: -theme.spacing(15),
                  // lg: -theme.spacing(12),
                },
                padding: 0,
              }}
            />
            </Grid>*/}
          {contentCollection.map((entry, index) => (
            <Grid
              key={`gridItem-${index}`}
              item
              xs={entry.xs}
              sm={entry.sm}
              md={entry.md}
              lg={entry.lg}
              xl={entry.xl}
            >
              <Markdown markdown={entry.markdown} suppressLinkUnderlines />
            </Grid>
          ))}
        </Grid>
      </PageLayout>
    </React.Fragment>
  );
};

export default Press;

export const pageQuery = graphql`
  {
    contentfulPage(slug: { eq: "press" }) {
      title
      slug
      headline
      coverImage {
        title
        description
        gatsbyImageData
        banner: gatsbyImageData(width: 1200, formats: JPG, sizes: "1200px")
        thumbnail: gatsbyImageData(width: 600, formats: JPG, sizes: "600px")
      }
      contentCollection {
        md
        sm
        xs
        lg
        xl
        markdown {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`;
